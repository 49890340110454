/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        if ($('#page-accordion')) {
          $('#page-accordion')
          .on('show.bs.collapse', function(e) {
            $(e.target).parent('.collapse-article').addClass('in');
          })
          .on('hide.bs.collapse', function(e) {
            $(e.target).parent('.collapse-article').removeClass('in');
          });
        }
      }
    },
    'helfer_werden': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        $('#acf-field_56729c9dcdcc6-backstage').parent('label').css('display', 'none');
        $('#acf-field_56729c9dcdcc6').parent('label').css('display', 'none');
        $('#acf-field_56729c9dcdcc6-bistro').parent('label').css('display', 'none');
        $('#acf-field_56729c9dcdcc6-deko').parent('label').css('display', 'none');
        $('#acf-field_56729c9dcdcc6-info').parent('label').css('display', 'none');
        $('#acf-field_56729c9dcdcc6-spiel').parent('label').css('display', 'none');
        $('.acf-field-56729beecdcc1').css('display', 'none');
        $('.acf-field-56729c60cdcc4').css('display', 'none');
      }
    },
    // Home page
    'single_helfer': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'archive': {
      init: function() {

        Isotope.Item.prototype._create = function() {
          // assign id, used for original-order sorting
          this.id = this.layout.itemGUID++;
          // transition objects
          this._transn = {
            ingProperties: {},
            clean: {},
            onEnd: {}
          };
          this.sortData = {};
        };

        Isotope.Item.prototype.layoutPosition = function() {
          this.emitEvent( 'layout', [ this ] );
        };

        Isotope.prototype.arrange = function( opts ) {
          // set any options pass
          this.option( opts );
          this._getIsInstant();
          // just filter
          this.filteredItems = this._filter( this.items );
          // flag for initalized
          this._isLayoutInited = true;
        };

        var NoneMode = Isotope.LayoutMode.create('none');

      },
      finalize: function() {

        // --- Helper Functions --- //
        // ------------------------ //

        function debounce( fn, threshold ) {
          var timeout;
          return function debounced() {
            if ( timeout ) {
              clearTimeout( timeout );
            }
            function delayed() {
              fn();
              timeout = null;
            }
            timeout = setTimeout( delayed, threshold || 100 );
          };
        }

        // --- Isotope Table --- //
        // --------------------- //

        var $table = $('.table-responsive').isotope({
          itemSelector: '.helfer-row',
          layoutMode: 'none',
          transitionDuration: 0,
          getSortData: {
             vorname: '[data-vorname]',
             nachname: '[data-nachname]',
             status: '[data-status]',
             email: '[data-email]',
             weight: function( itemElem ) {
               var weight = $( itemElem ).find('.weight').text();
               return parseFloat( weight.replace( /[\(\)]/g, '') );
             }
           },
           sortAscending: {
             vorname: false, // name ascendingly
             nachname: false, // weight descendingly
             status: true, // category ascendingly
           }
        });

        // --- OK-Filter --- //
        // ----------------- //

        function filterOk(okId){
          $table.isotope({
            filter: function() {
              var id = $(this).attr('data-ok');
              if (parseInt(okId) === 0) {
                return true;
              } else {
                return parseInt(id) === parseInt(okId);
              }
            }
          });
        }

        $('.ok-filter').on('change', function(e) {
          var okId = $(this).find('option:selected').val();
          State = History.getState();
          StateData = State.data;
          newStateData = {
            okFilter: parseInt(okId)
          };
          $.extend(StateData, newStateData);
          History.pushState(StateData, 'Helfer - Aufgetischt St. Gallen');
        });

        // $('th').on('click', function(e) {
        //   var sortByValue = $(this).attr('data-sorting');
        //   console.log('sorting by' + sortByValue);
        //   $table.isotope({ sortBy: sortByValue });
        // });

        // --- Email-Column --- //
        // -------------------- //

        function showEmail(show) {
          if(show === true) {
            $('.helfer-email').removeClass('inactive');
            $('.helfer-email-anzeigen').prop('checked', true);
          } else {
            $('.helfer-email').addClass('inactive');
            $('.helfer-email-anzeigen').prop('checked', false);
          }
        }

        $('input.helfer-email-anzeigen').on('change', function(e) {
          State = History.getState();
          StateData = State.data;
          newStateData = {
            showEmail: $(this).is(':checked')
          };
          $.extend(StateData, newStateData);
          History.pushState(StateData, 'Helfer - Aufgetischt St. Gallen');
        });

        // --- Telephone-Column --- //
        // ------------------------ //

        function showTelephone(show) {
          if(show === true) {
            $('.helfer-telefon').removeClass('inactive');
            $('.helfer-telefon-anzeigen').prop('checked', true);
          } else {
            $('.helfer-telefon').addClass('inactive');
            $('.helfer-telefon-anzeigen').prop('checked', false);
          }
        }

        $('input.helfer-telefon-anzeigen').on('change', function(e) {
          State = History.getState();
          StateData = State.data;
          newStateData = {
            showTelephone: $(this).is(':checked')
          };
          $.extend(StateData, newStateData);
          History.pushState(StateData, 'Helfer - Aufgetischt St. Gallen');
        });

        // --- Material-Column --- //
        // ----------------------- //

        function showMaterial(show) {
          if(show === true) {
            $('.helfer-material').removeClass('inactive');
            $('.helfer-material-anzeigen').prop('checked', true);
          } else {
            $('.helfer-material').addClass('inactive');
            $('.helfer-material-anzeigen').prop('checked', false);
          }
        }

        $('input.helfer-material-anzeigen').on('change', function(e) {
          State = History.getState();
          StateData = State.data;
          newStateData = {
            showMaterial: $(this).is(':checked')
          };
          $.extend(StateData, newStateData);
          History.pushState(StateData, 'Helfer - Aufgetischt St. Gallen');
        });

        // --- Cake-Column --- //
        // ------------------- //

        function showCake(show) {
          if(show === true) {
            $('.helfer-kuchen').removeClass('inactive');
            $('.helfer-kuchen-anzeigen').prop('checked', true);
          } else {
            $('.helfer-kuchen').addClass('inactive');
            $('.helfer-kuchen-anzeigen').prop('checked', false);
          }
        }

        $('input.helfer-kuchen-anzeigen').on('change', function(e) {
          State = History.getState();
          StateData = State.data;
          newStateData = {
            showCake: $(this).is(':checked')
          };
          $.extend(StateData, newStateData);
          History.pushState(StateData, 'Helfer - Aufgetischt St. Gallen');
        });

        // --- Planing-View --- //
        // ------------------- //

        function showView(view) {
          if (view === 'planung') {
            $('.helfer-status').addClass('inactive');
            $('.helfer-ok').addClass('inactive');
            $('.helfer-planung').removeClass('inactive');
          } else {
            $('.helfer-status').removeClass('inactive');
            $('.helfer-ok').removeClass('inactive');
            $('.helfer-planung').addClass('inactive');
          }
        }

        $('a.planungsansicht-anzeigen').on('click', function(e){
          State = History.getState();
          StateData = State.data;
          newStateData = {
            view: 'planung',
            showEmail: false,
            showTelephone: false,
            showMaterial: false,
            showCake: false
          };
          $.extend(StateData, newStateData);
          History.pushState(StateData, 'Helfer - Aufgetischt St. Gallen');
        });

        $('a.uebersicht-anzeigen').on('click', function(e){
          State = History.getState();
          StateData = State.data;
          newStateData = {
            view: 'uebersicht'
          };
          $.extend(StateData, newStateData);
          History.pushState(StateData, 'Helfer - Aufgetischt St. Gallen');
        });

        // --- Search-Filter --- //
        // ------------------- //

        var qsRegex;

        var $quicksearch = $('.quicksearch').keyup( debounce( function() {
          qsRegex = new RegExp( $quicksearch.val(), 'gi' );
          $table.isotope({
            filter: function() {
              return qsRegex ? $(this).text().match( qsRegex ) : true;
            }
          });
        }, 200 ) );

        // --- Email-List --- //
        // ------------------- //

        $('#email-list-button').on('click', function(e){
          $('#displayModal h4').html('E-Mail Liste');
          var emailList = [];
          if ($table.data('isotope').filteredItems.matches){
            $($table.data('isotope').filteredItems.matches).each(function(e){
              emailList.push(this.sortData.email);
            });
          } else {
            $($table.data('isotope').filteredItems).each(function(e){
              emailList.push(this.sortData.email);
            });
          }
          var outputEmailList = emailList.join('; ');
          $('#displayModal .modal-body').html('<pre style="max-width: 100%; white-space: inherit;">' + outputEmailList + '</pre>');
          $('#displayModal').modal();
        });

        // --- History Binding --- //
        // ------------------- //

        History.Adapter.bind(window,'statechange',function(){ // Note: We are using statechange instead of popstate
            var State = History.getState(); // Note: We are using History.getState() instead of event.state
            if (State.data.view){
              showView(State.data.view);
            }
            if (State.data.okFilter){
              filterOk(State.data.okFilter);
            }
            if (State.data.showEmail){
              showEmail(State.data.showEmail);
            } else {
              showEmail(false);
            }
            if (State.data.showTelephone){
              showTelephone(State.data.showTelephone);
            } else {
              showTelephone(false);
            }
            if (State.data.showMaterial){
              showMaterial(State.data.showMaterial);
            } else {
              showMaterial(false);
            }
            if (State.data.showCake){
              showCake(State.data.showCake);
            } else {
              showCake(false);
            }
        });

        $(window).load(function() {
          var State = History.getState(); // Note: We are using History.getState() instead of event.state
          if (State.data.view){
            showView(State.data.view);
          }
          if (State.data.okFilter){
            filterOk(State.data.okFilter);
          }
          if (State.data.showEmail){
            showEmail(State.data.showEmail);
          } else {
            showEmail(false);
          }
          if (State.data.showTelephone){
            showTelephone(State.data.showTelephone);
          } else {
            showTelephone(false);
          }
          if (State.data.showMaterial){
            showMaterial(State.data.showMaterial);
          } else {
            showMaterial(false);
          }
          if (State.data.showCake){
            showCake(State.data.showCake);
          } else {
            showCake(false);
          }
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
